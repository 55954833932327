import { createRouter, createWebHistory, } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/MainPage.vue')
  },
  // {
  //   path: '/test',
  //   component: () => import('../views/Test.vue')
  // },
  // {
  //   path: '/test2',
  //   component: () => import('../views/MainPage2.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
